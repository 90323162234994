// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mLM7ZdyHsaIq9FsI8DS9.MuiTooltip-arrow{left:60px !important}.qDMgcZHNZnSZ2yyQC3Ra{display:flex;cursor:pointer;gap:12px;max-height:48px;overflow:hidden}.gA0kuBwtg4ww3wOy91ek{margin-bottom:8px}.gA0kuBwtg4ww3wOy91ek.gx-dash-placeholder{position:relative;top:-4px}.gA0kuBwtg4ww3wOy91ek .gx-text-awarded{position:relative;top:-25px;line-height:26px}.E0qlE9kNhAqTz4QHR6Xz{display:inline}.hugXeVBjpEo7M6UMYFDi{display:inline}.hugXeVBjpEo7M6UMYFDi:hover{color:var(--color-interactions-hover-cta);background-color:var(--color-interactions-mild-berry);border-bottom:var(--color-interactions-hover-cta)}`, "",{"version":3,"sources":["webpack://./src/sections/scorecard-tab/components/scorecard/evaluation-scorecard/scorecard-products/scorecard-products.styles.module.scss"],"names":[],"mappings":"AACE,uCACE,oBAAA,CAIJ,sBACE,YAAA,CACA,cAAA,CACA,QAAA,CACA,eAAA,CACA,eAAA,CAGF,sBACE,iBAAA,CAEA,0CACE,iBAAA,CACA,QAAA,CAGF,uCACE,iBAAA,CACA,SAAA,CACA,gBAAA,CAIJ,sBACE,cAAA,CAGF,sBACE,cAAA,CACA,4BACE,yCAAA,CACA,qDAAA,CACA,iDAAA","sourcesContent":[".toolTip {\n  &:global(.MuiTooltip-arrow) {\n    left: 60px !important;\n  }\n}\n\n.vendorNameLogo {\n  display: flex;\n  cursor: pointer;\n  gap: 12px;\n  max-height: 48px;\n  overflow: hidden;\n}\n\n.overallScoreDisplay {\n  margin-bottom: 8px;\n\n  &:global(.gx-dash-placeholder) {\n    position: relative;\n    top: -4px;\n  }\n\n  & :global(.gx-text-awarded) {\n    position: relative;\n    top: -25px;\n    line-height: 26px;\n  }\n}\n\n.paraProgress {\n  display: inline;\n}\n\n.paraVendorName {\n  display: inline;\n  &:hover {\n    color: var(--color-interactions-hover-cta);\n    background-color: var(--color-interactions-mild-berry);\n    border-bottom: var(--color-interactions-hover-cta);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolTip": `mLM7ZdyHsaIq9FsI8DS9`,
	"vendorNameLogo": `qDMgcZHNZnSZ2yyQC3Ra`,
	"overallScoreDisplay": `gA0kuBwtg4ww3wOy91ek`,
	"paraProgress": `E0qlE9kNhAqTz4QHR6Xz`,
	"paraVendorName": `hugXeVBjpEo7M6UMYFDi`
};
export default ___CSS_LOADER_EXPORT___;

import { useContext, useRef, useState } from "react";
import classNames from "classnames";
import { colorPalette } from "gx-npm-common-styles";
import { GroupAddIcon, LightBulbOffIcon } from "gx-npm-icons";
import { InitStatus, InitUserRole, useUserInitAccess, UUID } from "gx-npm-lib";
import { Button, TypographyComponent, TooltipV2, RichTooltip } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { navigateToUrl } from "single-spa";
import { OverviewContext } from "../../overview.context";
import TeamMemberList from "../team-member-list/team-member-list.component";
import styles from "./team-management-card.styles.module.scss";

const TeamManagementCard = () => {
  const { t } = useTranslation();
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const { hasLoadedAccess, role } = useUserInitAccess(initiativeId);
  const { teamMembers, status } = useContext(OverviewContext);
  const refBulbIcon = useRef();
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const isViewOnly = hasLoadedAccess && role !== InitUserRole.OWNER;
  const iconFillColor =
    status === InitStatus.PLANNING
      ? isViewOnly
        ? colorPalette.neutrals.coal.hex
        : colorPalette.basic.white.hex
      : colorPalette.interactions.defaultCta.hex;

  return (
    <div className={styles.teamManagementCard}>
      <div className={styles.teamManagementHeader}>
        <TypographyComponent boldness="medium" rootClassName={styles.teamManagementTitle} styling="h4">
          {t("Team")}
        </TypographyComponent>
        <RichTooltip
          anchor={refBulbIcon.current}
          content={[
            {
              type: "PARAGRAPH",
              content: [t("Build a more inclusive buying team. Consider adding 3+ collaborators to your evaluation.")],
            },
            {
              type: "LIST_ITEMS",
              content: [
                t("Sponsor"),
                t("Operations"),
                t("Functional SME"),
                t("Finance"),
                t("Technical SME"),
                t("Legal"),
                t("Procurement/Vendor Management"),
              ],
            },
          ]}
          footerLinkText={t("Learn why")}
          footerLinkUrl="https://www.gartner.com/document/4232099"
          onClose={() => setTooltipOpen(false)}
          open={isTooltipOpen}
          theme="dark"
        >
          <span
            aria-label="bulb-icon-wrapper"
            className={classNames(styles.bulbIconWrapper, "gx-team-card-light-bulb-icon")}
            onClick={(event) => {
              event.stopPropagation();
              setTooltipOpen((prevState) => !prevState);
            }}
            onKeyDown={(event) => {
              event.stopPropagation();
              setTooltipOpen((prevState) => !prevState);
            }}
            ref={refBulbIcon as never}
            role="button"
            tabIndex={0}
          >
            <LightBulbOffIcon
              fillColor={
                isTooltipOpen ? colorPalette.interactions.selectedCta.hex : colorPalette.interactions.defaultCta.hex
              }
            />
          </span>
        </RichTooltip>
      </div>
      <TypographyComponent color="iron" rootClassName={styles.teamManagementSubHeading} styling="p3">
        {t("Stay connected to your team.")}
      </TypographyComponent>
      <div className={styles.teamMemberList}>
        <TeamMemberList memberList={teamMembers} />
      </div>
      <div>
        <TooltipV2
          deactivate={!isViewOnly}
          placement="top"
          PopperProps={{ modifiers: { offset: { offset: "5, 5" } } }}
          title={t("This action is only available to evaluation owners.")}
        >
          <div className={styles.tooltipButtonContainer}>
            <Button
              ariaLabel={t("Add team member to the initiative")}
              disabled={isViewOnly}
              onClick={() => navigateToUrl(`/s/evaluation/${initiativeId}/overview/team-management`)}
              rootClassName={status === InitStatus.PLANNING ? "btn-primary" : "btn-secondary"}
            >
              <GroupAddIcon fillPath={iconFillColor} />
              {t("TEAM MEMBERS")}
            </Button>
          </div>
        </TooltipV2>
      </div>
    </div>
  );
};

export default TeamManagementCard;

import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorBasicWhiteHex = colorPalette.basic.white.hex;
const colorDefaultCta = colorPalette.interactions.defaultCta.hex;
const colorPaleBerryHex = colorPalette.interactions.paleBerry.hex;
const colorLightBerryHex = colorPalette.interactions.lightBerry.hex;
const colorNeutralsCarbonHex = colorPalette.neutrals.carbon.hex;
const colorNeutralsCoalHex = colorPalette.neutrals.coal.hex;
const colorNeutralsIronHex = colorPalette.neutrals.iron.hex;
const colorNeutralsQuartzHex = colorPalette.neutrals.quartz.hex;
const colorNeutralsSilverHex = colorPalette.neutrals.silver.hex;
const colorNeutralsStoneHex = colorPalette.neutrals.stone.hex;
const colorNeutralsStoneRgb = colorPalette.neutrals.stone.rgb;
const colorNeutralsPewterHex = colorPalette.neutrals.pewter.hex;
const colorNeutralsPearl = colorPalette.neutrals.pearl.hex;
const weightSemiBoldAmount = weightPalette.semiBold.amount;
const weightSemiBoldVariation = weightPalette.semiBold.variation;
const screenedOutBgLines = `rgba(${colorNeutralsStoneRgb}, 0.7)`;
const getListHeaderStyles = (height = 94) => {
  const evaluationClasses = {
    container: {
      backgroundColor: colorNeutralsQuartzHex,
      height: "100%",
      width: "100%",
      borderRadius: "8px",
      "& tbody tr:first-of-type": {
        borderRadius: " 0 8px 0 0 ",
        zIndex: 1100,
      },
      "& .gx-cursor-pointer": {
        cursor: "pointer",
      },
      "&.gx-ftux-adjusted-container": { position: "relative", top: "-93px" },
      "& .gx-evaluations-scorecard-table": {
        "&$isExtended": {
          "& tbody": {
            "& tr:nth-child(1)": {
              "& td": {
                "& .gx-evaluations-scorecard-table-vendor": {
                  alignItems: "unset",
                  paddingTop: "3px",
                  "& .gx-evaluations-scorecard-table-vendor-name": {
                    paddingTop: "4px",
                  },
                },
              },
            },
          },
          "& tfoot": {
            "& tr:nth-child(1)": {
              "& td": {},
            },
          },
          "& tr.gx-req-vendor-head-row th, td.gx-req-vendor-body-row-td": {
            "& > div.header-wrapper": {
              height: `${height - 36}px`,
              marginTop: "16px",
              "& > div.header-buttons": {
                display: "flex",
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                marginLeft: "auto",
                marginRight: "auto",
              },
            },
          },
          "& td.gx-req-vendor-body-row-td": {
            justifyContent: "unset",
          },
        },
        width: "100%",
        minWidth: 670,
        borderRadius: 8,
        backgroundColor: colorBasicWhiteHex,
        color: "black",
        marginBottom: "22px",
        "& thead tr.gx-req-root-head-row": {
          "& th": {
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            "& div.gx-req-item-name-container": {
              width: "100%",
            },
            "& div.gx-req-root-category": {
              display: "flex",
              alignItems: "center",
              width: "100%",
              paddingLeft: "0px",
              gap: "16px",
              "& .gx-req-root-category-name": {
                paddingRight: "16px",
                borderRight: `1px solid ${colorNeutralsStoneHex}`,
              },
            },
            "& ul.gx-req-item-list": {
              paddingLeft: 0,
              margin: 0,
              listStyleType: "none",
              "&.right-border": {
                borderRight: `${colorNeutralsSilverHex} 1px solid`,
              },
              "& li.gx-req-item": {
                paddingLeft: "0px",
                lineHeight: "24px",
                "& .italic": {
                  fontStyle: "italic",
                },
              },
            },
          },
          "&.gx-req-scoring-assignment": {
            "& th": {
              "& div.gx-req-root-category": {
                gap: 0,
                paddingRight: 28,
                "&.req-root-category-open": {
                  boxShadow: `inset 0px -1px 0px ${colorNeutralsStoneHex}`,
                },
                "&.right-border": {
                  borderRight: `${colorNeutralsSilverHex} 1px solid`,
                },
                "&.no-right-border": {
                  borderRight: 0,
                },
                "& .gx-req-root-category-name-weight-wrapper": {
                  alignItems: "baseline",
                  display: "flex",
                  width: "70%",
                  "& .gx-req-root-category-name": {
                    borderRight: 0,
                    display: "inline",
                    marginLeft: 7,
                    paddingRight: 0,
                  },
                  "& .gx-req-root-category-weight": {
                    color: colorNeutralsIronHex,
                    display: "inline",
                    marginLeft: 12,
                  },
                },
              },
              "& .right-border-top": {
                position: "absolute",
                right: 0,
                bottom: 0,
                height: "calc(100% - 20px)",
                borderRight: `${colorNeutralsSilverHex} 1px solid`,
              },
              "& .right-border-bottom": {
                position: "absolute",
                right: 0,
                top: 0,
                height: "calc(100% - 20px)",
                borderRight: `${colorNeutralsSilverHex} 1px solid`,
              },
            },
          },
        },
        "& thead tr.gx-req-root-head-row.gx-last-req-root": {
          "& th": {
            "& li.gx-req-item:last-child": {
              boxShadow: "none",
              paddingBottom: "32px",
            },
          },
        },
        "& tbody tr.gx-req-root-body-row": {
          "& td": {
            padding: "0px",
            "& div.gx-req-item-score-container": {
              width: "100%",
            },
            "& div.gx-req-root-category-score": {
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              minHeight: "56px",
              width: "100%",
              "&.gx-req-cat-col": {
                "&.hovered": {
                  backgroundColor: colorPalette.interactions.lightBerry.hex,
                },
                "&.selected": {
                  backgroundColor: colorPalette.interactions.mildBerry.hex,
                },
                "&.expanded": {
                  boxShadow: `inset 0px -1px 0px ${colorNeutralsStoneHex}`,
                },
              },
            },
          },
          "& ul.gx-req-item-list-td": {
            paddingLeft: 0,
            margin: 0,
            listStyleType: "none",
            "& li.gx-req-item-td": {
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              minHeight: "56px",
              "&.gx-req-item-col": {
                height: "100%",
                padding: 0,
                width: "100%",
              },
              "&.hovered": {
                backgroundColor: colorPalette.interactions.lightBerry.hex,
              },
              "&.selected": {
                backgroundColor: colorPalette.interactions.mildBerry.hex,
              },
              "& .gx-req-item-col-child": {
                alignItems: "center",
                display: "inline-flex",
                justifyContent: "center",
                height: "100%",
                width: "100%",
              },
            },
          },
        },
        "& tbody tr.gx-req-root-body-row:last-child": {
          "& td": {
            "& li.gx-req-item-td:last-child": {
              boxShadow: "none",
              paddingBottom: "32px",
            },
          },
        },
        "&.gx-hide-requirement-items": {
          "& thead": {
            "& tr.gx-req-root-head-row.gx-last-req-root": {
              "& th": {
                "& div.gx-req-root-category": {
                  boxShadow: "none",
                  paddingBottom: "32px",
                },
              },
            },
          },
          "& tbody": {
            "& tr.gx-req-root-body-row:last-child": {
              "& td": {
                "& div.gx-req-root-category-score": {
                  boxShadow: "none",
                  paddingBottom: "32px",
                },
              },
            },
          },
          "& tfoot": {
            "& tr:last-child": {
              "& td": {
                "& div.gx-req-root-footer": {
                  boxShadow: "none",
                  "& div.gx-footer-divider": {
                    borderRadius: "0 0 8px 0",
                  },
                },
              },
            },
          },
        },
        "&.gx-show-requirement-items": {
          "& tfoot": {
            "& tr:last-child": {
              "& td": {
                "& li.gx-req-item-footer:last-child": {
                  boxShadow: "none",
                  "& div.gx-footer-divider": {
                    borderRadius: "0 0 8px 0",
                  },
                },
              },
            },
          },
        },
        "&.header-shadow": {
          "& thead tr:nth-child(1), tfoot tr:nth-child(1)": {
            "& td, th": {
              borderBottom: `1px solid ${colorNeutralsSilverHex}`,
            },
          },
          "& thead tr:nth-child(1)": {
            display: "block",
            "& $headerBoxShadowWrapper": {
              overflow: "hidden",
              paddingBottom: "10px",
              width: "102%",
              left: "0px",
              position: "absolute",
              bottom: `-10px`,
              "& $headerBoxShadow": {
                boxShadow: "0 4px 6px -2px rgb(20 19 18 / 20%)",
                width: "100%",
                height: "20px",
              },
            },
            "& th": {
              boxShadow: "none",
            },
          },
          "& tfoot tr:nth-child(1)": {
            display: "block",
            "&::after": {
              bottom: 0,
              boxShadow: "0 8px 6px -6px rgb(20 19 18 / 20%)",
              content: `""`,
              height: 20,
              left: -11,
              position: "absolute",
              width: "118%",
            },
            "& td": {
              boxShadow: "none",
            },
          },
        },
        "&.not-empty.header-shadow": {
          "& tbody tr:nth-child(1)": {
            boxShadow: "0px 8px 6px -6px rgb(20 19 18 / 20%)",
            "& td": {
              boxShadow: "none",
              borderBottom: `1px solid ${colorNeutralsSilverHex}`,
            },
          },
        },
        "&.empty.header-shadow": {
          "& thead tr:nth-child(1)": {
            "&::after": {
              width: "100%",
            },
          },
        },
        "&.no-footer": {
          "& tbody": {
            "& tr:nth-child(1)": {
              "& td:last-child": {
                borderRadius: "0px 8px 0px 0px",
              },
            },
          },
        },
        "& .gx-screened-out-bk-gnd": {
          backgroundImage: `linear-gradient(135deg, transparent 42.86%, ${screenedOutBgLines} 42.86%, ${screenedOutBgLines} 50%, transparent 50%, transparent 92.86%, ${screenedOutBgLines} 92.86%, ${screenedOutBgLines} 100%)`,
          backgroundSize: "9.90px 9.90px",
          "& img": {
            filter: "grayscale(1)",
          },
          "& .gx-vendor-avatar": {
            backgroundColor: colorNeutralsPewterHex,
          },
        },
        "& thead tr:nth-child(2) th, & tbody tr:nth-child(2) td": {
          minHeight: 163,
        },
        "& thead, & tbody, & tfoot": {
          "& tr:last-child td": {
            paddingBottom: 32,
          },
        },
        "& thead": {
          minWidth: 402,
          "& h5": {
            color: colorNeutralsCarbonHex,
            fontSize: "20px",
            lineHeight: "26px",
            letterSpacing: "0.15px",
            fontVariationSettings: weightSemiBoldVariation,
            fontWeight: weightSemiBoldAmount,
            marginBottom: 8,
          },
          "& .gx-evaluations-scorecard-table-update-requirements-desc": {
            color: colorNeutralsIronHex,
            display: "inline",
          },
          "& .gx-evaluations-scorecard-table-update-your-requirements": {
            alignItems: "center",
            display: "inline-flex",
            marginBottom: "7px",
            marginLeft: 10,
          },
          "& .gx-header-toggle": {
            color: colorNeutralsCoalHex,
          },
          "& tr:nth-child(1)": {
            zIndex: 1101,
            "& th": {
              alignItems: "center",
              backgroundColor: colorBasicWhiteHex,
              borderRadius: "8px 0 0 0",
              display: "flex",
              height: height,
              padding: "23px 0px 23px 32px",
              "& .gx-evaluations-scorecard-table-add-vendor": {
                display: "flex",
                alignItems: "center",
                background: "none",
                border: "none",
                padding: 0,
                color: colorDefaultCta,
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
                "&:disabled": {
                  backgroundColor: `${colorBasicWhiteHex} !important`,
                  textDecoration: "none",
                  pointerEvents: "none",
                  "& .gx-evaluations-scorecard-table-add-vendor-button-text": {
                    color: colorNeutralsIronHex,
                  },
                  "& svg": {
                    filter: "grayscale(1)",
                  },
                },
                "& svg": {
                  marginLeft: 18,
                  marginRight: 8,
                },
              },
              "& button.gx-table-carousel-left-scroll": {
                marginLeft: "auto",
                "&  svg": {
                  transform: "rotate(90deg)",
                },
                "&.disabled": {
                  "& svg path": {
                    fill: `${colorNeutralsStoneHex} !important`,
                  },
                },
                "&.enabled": {
                  "& svg path": {
                    fill: `${colorNeutralsIronHex} !important`,
                  },
                },
              },
            },
          },
          "& tr:nth-child(2) th": {
            display: "block",
          },
          "& tr:nth-child(n+2)": {
            "& th": {
              paddingLeft: 0,
              marginLeft: 32,
            },
          },
          "& tr:nth-child(2)": {
            "& th": {
              display: "block",
            },
          },
          "& tr:nth-child(n+3)": {
            "& th": {
              display: "flex",
              alignItems: "center",
              "& .pipe": {
                height: 22,
                borderLeft: `1px solid ${colorNeutralsStoneHex}`,
                paddingLeft: 16,
                marginLeft: 16,
              },
            },
          },
        },
        "&.empty": {
          backgroundColor: colorBasicWhiteHex,
          "& thead": {
            borderRadius: 8,
            backgroundColor: colorBasicWhiteHex,
            width: "62%",
            display: "grid",
            "& tr": {
              "&:nth-child(1)": {
                "& th": {
                  borderRadius: "8px 8px 0 0",
                },
              },
              "& th": {
                width: "100%",
              },
            },
            "& tr:nth-child(n+2)": {
              overflow: "hidden",
            },
          },
          "& tbody": {
            backgroundColor: "transparent",
            "& tr": {
              height: "100%",
              alignItems: "start",
              justifyContent: "center",
              whiteSpace: "break-spaces",
              "& td": {
                textAlign: "center",
                backgroundColor: "transparent",
                "& img": {
                  marginBottom: 55,
                },
                "& button": {
                  borderRadius: 0,
                },
              },
            },
          },
        },
        "& tbody tr": {
          zIndex: 1,
        },
        "&.not-empty": {
          "& tbody": {
            minWidth: 434,
            "& tr:nth-child(1)": {
              backgroundColor: colorNeutralsQuartzHex,
              "& td": {
                display: "flex",
                position: "relative",
                height: height,
                padding: 0,
                backgroundColor: colorBasicWhiteHex,
                "& > div": {
                  borderLeft: `1px solid ${colorNeutralsSilverHex}`,
                  width: "100%",
                  textAlign: "center",
                  height: 62,
                  "& > div": {
                    marginBottom: 10,
                    paddingLeft: 12,
                    paddingRight: 12,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    "& img": {
                      width: 32,
                      height: 32,
                    },
                    "& svg": {
                      minWidth: "32px",
                    },
                    "& > div": {
                      padding: "3.75px",
                    },
                    "& p": {
                      textAlign: "left",
                    },
                  },
                },
                "& .gx-evaluations-scorecard-table-vendor": {
                  marginBottom: 0,
                },
                "& .gx-evaluations-scorecard-table-vendor-view-eval": {
                  marginTop: "1px",
                },
              },
            },
            "& tr:nth-child(2)": {
              "& td": {
                paddingTop: 54,
                paddingBottom: 54,
                textAlign: "center",
                "& > h5, & > p": {
                  width: "100%",
                },
                "& h5": {
                  fontSize: "20px",
                  lineHeight: "26px",
                  letterSpacing: "0.15px",
                  color: colorNeutralsCarbonHex,
                  fontVariationSettings: weightSemiBoldVariation,
                  fontWeight: weightSemiBoldAmount,
                },
                "& h5.gx-screened-out-text-color": {
                  color: colorNeutralsIronHex,
                },
                "& p": { color: colorNeutralsIronHex },
              },
            },
            "& tr:nth-child(n+3)": {
              "& td": {
                fontSize: "18px",
                color: colorNeutralsCarbonHex,
                fontVariationSettings: weightSemiBoldVariation,
                fontWeight: weightSemiBoldAmount,
              },
              "&.gx-reqItem-body-row": {
                "& td": {},
              },
            },
            "& tr:nth-child(n+3) td.gx-screened-out-bk-gnd": {
              color: colorNeutralsIronHex,
            },
            "& tr td:nth-child(odd)": {
              backgroundColor: colorNeutralsPearl,
            },
            "& tr td:nth-child(even)": {
              backgroundColor: colorBasicWhiteHex,
            },
            "& tr:last-child": {
              "& td:last-child": {
                borderRadius: "0 0 8px 0",
              },
            },
            "& td": {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            },
          },
          "& tfoot": {
            backgroundColor: colorNeutralsQuartzHex,
            borderRadius: 8,
            width: 64,
            "& tr:nth-child(1)": {
              backgroundColor: "neutral",
              zIndex: 1101,
              "& td": {
                boxShadow: `inset 0px -1px 0px ${colorNeutralsStoneHex}`,
                backgroundColor: colorBasicWhiteHex,
                borderRadius: "0 8px 0 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: height,
                "& > div": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: height - 32,
                  borderLeft: `1px solid ${colorNeutralsSilverHex}`,
                  width: "100%",
                  textAlign: "center",
                },
                "& button.gx-table-carousel-right-scroll": {
                  "& svg": {
                    transform: "rotate(-90deg)",
                  },
                  "&.disabled": {
                    "& svg path": {
                      fill: `${colorNeutralsStoneHex} !important`,
                    },
                  },
                  "&.enabled": {
                    "& svg path": {
                      fill: `${colorNeutralsIronHex} !important`,
                    },
                  },
                },
              },
            },
            "& td": {
              minWidth: 64,
              paddingLeft: 0,
              paddingRight: 0,
              "& div.gx-footer-divider": {
                height: "100%",
                background: `linear-gradient(274.01deg, ${colorBasicWhiteHex} 10.84%, rgba(249, 249, 249, 0) 95.69%)`,
              },
            },
            "& tr:nth-child(n+2) td": {
              paddingTop: 0,
              paddingBottom: 0,
            },
            "& tr:nth-child(2) td": {
              boxShadow: `inset 0px -1px 0px ${colorNeutralsStoneHex}`,
              height: 163,
            },
            "& tr:last-child td": {
              boxShadow: "none",
              padding: "0 !important",
              borderRadius: "0 0 8px 0",
            },
            "& ul": {
              padding: 0,
              margin: 0,
            },
            "& li": {
              paddingLeft: 0,
              margin: 0,
              listStyleType: "none",
            },
            "& div.gx-req-root-footer, li.gx-req-item-footer": {
              boxShadow: `inset 0px -1px 0px ${colorNeutralsStoneHex}`,
            },
          },
          "&.nav-next-color-white": {
            "& tfoot tr:nth-child(n+2) td": {
              backgroundColor: colorBasicWhiteHex,
            },
          },
        },
        "&.gx-evaluations-scorecard-table-wrap": {
          "&.num-cols-2": {
            "& tbody": {
              "& td": {
                "&.gx-awarded": {
                  minWidth: "52%",
                  "@media (max-width:1023px)": {
                    minWidth: "100%",
                  },
                },
                "&.gx-not-awarded": {
                  minWidth: "48%",
                  "@media (max-width:1023px)": {
                    minWidth: "100%",
                  },
                },
              },
            },
          },
          "&.num-cols-3": {
            "& tbody": {
              "& td": {
                "&.gx-awarded": {
                  minWidth: "40%",
                  "@media (max-width:1239px)": {
                    minWidth: "60%",
                  },
                  "@media (max-width:1023px)": {
                    minWidth: "100%",
                  },
                },
                "&.gx-not-awarded": {
                  minWidth: "30%",
                  "@media (max-width:1239px)": {
                    minWidth: "40%",
                  },
                  "@media (max-width:1023px)": {
                    minWidth: "100%",
                  },
                },
              },
            },
          },

          "& tbody": {
            "& td": {
              "&.gx-awarded": {
                minWidth: "33.3333%",
                "@media (max-width:1439px)": {
                  minWidth: "44%",
                },
                "@media (max-width:1239px)": {
                  minWidth: "60%",
                },
                "@media (max-width:1023px)": {
                  minWidth: "100%",
                },
              },
              "&.gx-not-awarded": {
                minWidth: "22.2222%",
                "@media (max-width:1439px)": {
                  minWidth: "28%",
                },
                "@media (max-width:1239px)": {
                  minWidth: "40%",
                },
                "@media (max-width:1023px)": {
                  minWidth: "100%",
                },
              },
            },
          },
        },
        "&.num-cols-1": {
          "& tbody": {
            "& td": {
              minWidth: "100% !important",
            },
          },
        },
        "&.num-cols-2": {
          "& tbody": {
            "& td": {
              minWidth: "50%",
              "@media (max-width:1023px)": {
                minWidth: "100%",
              },
            },
          },
        },
        "&.num-cols-3": {
          "& tbody": {
            "& td": {
              minWidth: "33.3333%",
              "@media (max-width:1239px)": {
                minWidth: "50%",
              },
              "@media (max-width:1023px)": {
                minWidth: "100%",
              },
            },
          },
        },
        "& th li.gx-req-item, td li.gx-req-item-td, th .gx-req-root-category, td div.gx-req-root-category-score": {
          boxShadow: `inset 0px -1px 0px ${colorNeutralsSilverHex}`,
          paddingLeft: 32,
          paddingTop: 16,
          paddingBottom: 16,
          paddingRight: 32,
        },
        "& tr.gx-req-scorecard-head-row th, td.gx-req-scorecard-body-row-td, td li.gx-req-item-td:last-child": {
          boxShadow: `inset 0px -1px 0px ${colorNeutralsStoneHex}`,
          paddingLeft: 32,
          paddingTop: 16,
          paddingBottom: 16,
          paddingRight: 32,
        },
        "& tr.gx-req-scorecard-head-row th .medium-heading": {
          fontWeight: weightPalette.medium.amount,
          fontVariationSettings: weightPalette.medium.variation,
        },
        "& td li.gx-req-item-td:first-child": {
          boxShadow: `inset 0px -1px 0px ${colorNeutralsSilverHex}`,
        },
        "& .gx-req-item:last-child": {
          boxShadow: "none",
        },
        "& .gx-req-item-list": {
          boxShadow: `inset 0px -1px 0px ${colorNeutralsStoneHex}`,
        },
        "& .gx-last-req-root": {
          "& .gx-req-item-list": {
            boxShadow: "none",
          },
        },
        "& span.selectedIcon": {
          margin: "-54px 0 11px 0",
        },
        "& thead, & tbody": {
          "& tr": {
            "&:last-child": {
              "& th, & td": {
                boxShadow: "none",
              },
            },
          },
        },
        "&.gx-evaluations-scorecard-tableV2": {
          "& thead tr:nth-child(2) th, & tbody tr:nth-child(2) td": {
            minHeight: 112,
            backgroundColor: colorPaleBerryHex,
          },
          "& thead tr.gx-req-root-head-row th": {
            "& ul.gx-req-item-list": {
              paddingLeft: 63,
            },
          },
          "&.gx-evaluations-scorecard-awarded": {
            "& thead tr:nth-child(2) th, & tbody tr:nth-child(2) td, & tfoot tr:nth-child(2) td": {
              height: 124,
            },
          },
          "& thead": {
            "& tr:nth-child(n+2)": {
              "& th:nth-child(n+1)": {
                paddingLeft: 32,
                marginLeft: 0,
                position: "relative",
              },
            },
          },
          "&.not-empty": {
            "& tbody": {
              "& tr:nth-child(2)": {
                "& td": {
                  paddingTop: 25,
                  paddingBottom: 25,
                  height: 112,
                  "& h5": {
                    fontSize: "36px",
                    marginBottom: 8,
                    lineHeight: "26px",
                    letterSpacing: "0.15px",
                    color: colorNeutralsCarbonHex,
                    fontVariationSettings: weightSemiBoldVariation,
                    fontWeight: weightSemiBoldAmount,
                  },
                  "& h5.gx-screened-out-text-color": {
                    color: colorNeutralsIronHex,
                  },
                  "& h5.gx-dash-placeholder": {
                    position: "relative",
                    top: -4,
                    "&.gx-text-awarded": {
                      position: "relative",
                      top: -25,
                      lineHeight: "26px",
                    },
                  },
                },
                "& td:nth-child(odd)": {
                  backgroundColor: colorLightBerryHex,
                },
                "& td:nth-child(even)": {
                  backgroundColor: colorPaleBerryHex,
                },
                "& .gx-p-awarded": { position: "relative", top: -19 },
                "& .gx-text-awarded": {
                  position: "relative",
                  top: -25,
                  lineHeight: "26px",
                },
                "& span.selectedIcon": {
                  margin: "-15px 144px 11px 0",
                },
                "& .gx-complete-text": {
                  color: colorPalette.status.darkPear.hex,
                },
              },
            },
            "& tfoot": {
              backgroundColor: colorNeutralsPearl,
              "& td": {
                "& div.gx-footer-divider": {
                  height: "100%",
                  background: `none`,
                },
              },
              "& tr:nth-child(2) td": {
                height: 112,
              },
              "& div.gx-req-root-footer, li.gx-req-item-footer": {
                "&.gx-item-expanded": { height: 30 },
              },
            },
            "&.nav-next-color-white": {
              "& tfoot tr:nth-child(2) td": {
                backgroundColor: colorBasicWhiteHex,
              },
            },
            "&.nav-next-color-gray": {
              "& tfoot tr:nth-child(n+2) td": {
                backgroundColor: colorNeutralsPearl,
              },
            },
          },
          "& .gx-req-scorecard-head-row th": {
            paddingBottom: 11,
            backgroundColor: colorPaleBerryHex,
          },
        },
        "&.gx-evaluations-scorecard-table.gx-evaluations-scorecard-tableV2": {
          "& tbody": {
            "& tr:nth-child(1) td": {
              boxShadow: `inset 0px -1px 0px ${colorNeutralsSilverHex}`,
            },
            "& tr:nth-child(2) td": {
              backgroundColor: colorBasicWhiteHex,
              minHeight: 64,
              "& > div": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "6px",
                position: "relative",
                borderLeft: `1px solid ${colorNeutralsSilverHex}`,
                minHeight: 32,
                width: "100%",
              },
              "& .gx-popover-menu-root": {
                position: "absolute",
                right: "2px",
                background: colorBasicWhiteHex,
                "& svg": {
                  zIndex: "auto",
                },
              },
              "& p": {
                color: colorNeutralsCarbonHex,
              },
            },
            "& tr:nth-child(3)": {
              "& td:nth-child(odd)": {
                backgroundColor: colorLightBerryHex,
              },
              "& td:nth-child(even)": {
                backgroundColor: colorPaleBerryHex,
              },
              "& td": {
                minHeight: 112,
                boxShadow: "none",
              },
            },
          },
          "& thead": {
            "& tr:nth-child(1) th": {
              boxShadow: `inset 0px -1px 0px ${colorNeutralsSilverHex}`,
            },
            "& tr:nth-child(2) th": {},
            "& tr:nth-child(3) th": {
              minHeight: 163,
              boxShadow: "none",
            },
          },
          "& .gx-req-scorecard-head-row th:nth-child(1)": {
            display: "block",
            minHeight: 112,
          },
          "& .gx-req-scorecard-eval-row th:nth-child(1)": {
            backgroundColor: colorBasicWhiteHex,
            minHeight: 64,
            display: "flex",
            alignItems: "center",
          },
          "&.gx-evaluations-scorecard-awarded": {
            "& thead tr:nth-child(3) th, & tbody tr:nth-child(3) td": {
              height: 112,
            },
            "& tfoot tr:nth-child(2) td": {
              height: 176,
            },
            "& thead tr:nth-child(2) th, & tbody tr:nth-child(2) td": {
              height: 64,
            },
          },
          "&.not-empty tbody tr:nth-child(2) td:nth-child(n)": {
            backgroundColor: colorBasicWhiteHex,
            height: 64,
          },
          "&.not-empty tfoot tr:nth-child(2) td": {
            height: 176,
            display: "block",
            "& .gx-footer-divider": {
              height: 64,
              boxShadow: `inset 0px -1px 0px ${colorNeutralsStoneHex}`,
            },
          },
        },
      },
      "& $btnAddVendor": {
        color: colorDefaultCta,
        display: "inline",
      },
    },
    vendorHeadCountWrapper: {
      display: "flex",
      height: "60px",
      alignItems: "center",
      width: "100%",
    },
    headerBoxShadowWrapper: {},
    headerBoxShadow: {},
    gxRightBorder: {
      borderRight: `${colorNeutralsSilverHex} 1px solid`,
    },
    btnAddVendor: {},
    vendorBtn: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& p": {
        marginLeft: 4,
      },
    },
    noVendorsWrapper: {
      padding: "114px 56px 32px 56px",
    },
    paraProgress: {
      display: "inline",
    },
    paraScreenedOut: {
      display: "inline",
    },
    paraVendorName: {
      display: "inline",
      "&:hover": {
        color: colorPalette.interactions.hoverCta.hex,
        backgroundColor: colorPalette.interactions.mildBerry.hex,
        borderBottom: `1px solid ${colorPalette.interactions.hoverCta.hex}`,
      },
    },
    vendorNameLogo: {
      display: "flex",
      cursor: "pointer",
      gap: 12,
      maxHeight: 48,
      overflow: "hidden",
    },
    toolTip: {
      "& .MuiTooltip-arrow": {
        left: `60px !important`,
      },
    },
    isExtended: {},
    addVendorTooltip: {
      width: "auto ",
      textAlign: "center",
      top: "-6px !important",
      left: "-35px !important",
    },
    overallScoreDisplay: {
      marginBottom: 8,
      "&.gx-dash-placeholder": {
        position: "relative",
        top: -4,
      },
      "& .gx-text-awarded": {
        position: "relative",
        top: -25,
        lineHeight: "26px",
      },
    },
  };
  return {
    ...evaluationClasses,
  };
};

export { getListHeaderStyles };

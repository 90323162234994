import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorWhite = colorPalette.basic.white.hex;
const colorLightBerry = colorPalette.interactions.lightBerry.hex;

const requirementListStyles = {
  content: {
    color: colorPalette.basic.black.hex,
    marginBottom: "24px",
    "& .gx-dnd-list .gx-list-header.gx-dnd-req-cat-header": {
      padding: 0,
      "&.gx-imported-cat": {
        animationDuration: "4s",
        animationIterationCount: "1",
        animationName: "$importBackground",
        animationTimingFunction: "ease-in-out",
      },
      "&.collapse .gx-item-modified": {
        borderBottomLeftRadius: "8px",
      },
    },
    "& .gx-dnd-item": {
      alignItems: "center",
      display: "flex",
      padding: "1px 0",
      "& .gx-dnd-icon": {
        flexBasis: "48px",
        minWidth: "48px",
      },
      "&:hover, &:hover .gx-req-text-area textarea": {
        backgroundColor: colorPalette.basic.white.hex,
      },
      "&.hover": {
        backgroundColor: colorPalette.neutrals.pearl.hex,
      },
      "&.hover .gx-req-text-area textarea": {
        backgroundColor: colorPalette.neutrals.pearl.hex,
      },
      "&.edit, &.edit .gx-req-text-area textarea": {
        backgroundColor: colorLightBerry,
      },
    },
    "& .hover .gx-req-cat-delete-icon .tool-tip button": {
      "&:focus, &:hover": {
        "& svg, & path": {
          fill: colorPalette.status.poisonCherry.hex,
        },
      },
      "& svg, & path": {
        fill: colorPalette.neutrals.iron.hex,
      },
    },
    "& .gx-dnd-list.deleting": {
      marginBottom: 0,
    },
    "& .gx-list-footer-btn-add-vertical-line": {
      borderLeft: `1px solid ${colorPalette.neutrals.silver.hex}`,
      height: "48px",
      marginLeft: "38px",
      marginRight: "39px",
    },
    "& .gx-add-library-btn-label": {
      fontWeight: weightPalette.bold.amount,
      fontSize: "15px",
      lineHeight: "26px",
      letterSpacing: "0.75px",
    },
  },
  "@keyframes importBackground": {
    "0%": { backgroundColor: colorLightBerry },
    "75.0%": { backgroundColor: colorLightBerry },
    "100.0%": { backgroundColor: colorWhite },
  },
};

export { requirementListStyles };

import { useCaptureEventsV2, UUID } from "gx-npm-lib";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ClientEvent } from "../../app.constants";
import TabPanelComponent from "../../ui/tab-panel/tab-panel.component";
import CheckListSection from "./check-list-section.component";
import ChecklistErrorComponent from "./components/checklist-error/checklist-error.component";

const ChecklistV2Component = () => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const [searchParams] = useSearchParams();
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    const gxtmSource = searchParams.get("gxtm_source") || "";
    const gxtmType = searchParams.get("gxtm_type") || "";
    captureEvents([
      {
        eventType: ClientEvent.INITIATIVE_CHECKLIST_PAGE_VIEWED,
        metaData: { initiativeId, gxtmType, gxtmSource },
      },
    ]);
  }, [captureEvents, initiativeId, searchParams]);

  return (
    <TabPanelComponent>
      <CheckListSection initiativeId={initiativeId} />
      <ChecklistErrorComponent />
    </TabPanelComponent>
  );
};

export default ChecklistV2Component;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ha2sbxfIl8QpNpHBQeEK{width:100%}.TtVwgJIwSfRSL_F7ZumF{padding-left:63px;margin:0;list-style-type:none}.TtVwgJIwSfRSL_F7ZumF .Ldg38VkwYK4Welv1AsbS{line-height:24px;box-shadow:inset 0 -1px 0 var(--color-neutrals-silver);padding:16px 32px 16px 0}.TtVwgJIwSfRSL_F7ZumF .Ldg38VkwYK4Welv1AsbS .l6efWGGFwgq5lnLEwnPp{font-style:italic}.TtVwgJIwSfRSL_F7ZumF.GBeBxGQRDRkwC1raU2XF{border-right:var(--color-neutrals-silver) 1px solid}`, "",{"version":3,"sources":["webpack://./src/sections/scorecard-tab/components/scorecard/evaluation-scorecard/scorecard-requirements/scorecard-requirement-item/scorecard-requirement-item.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,oBAAA,CAEA,4CACE,gBAAA,CACA,sDAAA,CACA,wBAAA,CAEA,kEACE,iBAAA,CAKN,2CACE,mDAAA","sourcesContent":[".reqItemNameContainer {\n  width: 100%;\n}\n\n.reqItemList {\n  padding-left: 63px;\n  margin: 0;\n  list-style-type: none;\n\n  .reqItem {\n    line-height: 24px;\n    box-shadow: inset 0 -1px 0 var(--color-neutrals-silver);\n    padding: 16px 32px 16px 0;\n\n    .italic {\n      font-style: italic;\n    }\n  }\n}\n\n.reqItemList.rightBorder {\n  border-right: var(--color-neutrals-silver) 1px solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reqItemNameContainer": `ha2sbxfIl8QpNpHBQeEK`,
	"reqItemList": `TtVwgJIwSfRSL_F7ZumF`,
	"reqItem": `Ldg38VkwYK4Welv1AsbS`,
	"italic": `l6efWGGFwgq5lnLEwnPp`,
	"rightBorder": `GBeBxGQRDRkwC1raU2XF`
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { useTranslation } from "react-i18next";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import styles from "./calculating-scores.styles.module.scss";
import CalculatingScoresImg from "../../../../../../../assets/images/quick-start/scorecard/calculating-scores.svg";
import classNames from "classnames";

const CalculatingScoresComponent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <TypographyComponent styling={"h5"} boldness={"medium"}>
        {t("Calculating scores")}
      </TypographyComponent>
      <img
        src={CalculatingScoresImg}
        alt={t("Calculating scores")}
        className={classNames(styles.calculatingScoresImg, styles.imgSize)}
      />
      <div className={styles.description}>
        <TypographyComponent rootClassName={styles.heading} styling={"p2"} boldness={"medium"}>
          {t("Final scores for individual requirements are determined by averaging scores from all assigned scorers.")}
        </TypographyComponent>
        <TypographyComponent rootClassName={styles.additionalInfo} styling={"p2"} boldness={"regular"}>
          {t(
            "Requirement priorities and category weights are used to influence the final score for any given vendor. A detailed breakdown of scoring can be found in the complete Help Center article below."
          )}
        </TypographyComponent>
        <div className={styles.helpLink}>
          <TextLink
            text={t("Read more about how scores are calculated")}
            target={"_blank"}
            href={
              "https://intercom.help/gartner-buysmart-help-center/en/articles/6276044-how-scoring-works#h_7dfd5bb789"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CalculatingScoresComponent;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { TypographyComponent } from "gx-npm-ui";
import { ScoringMode, UUID } from "gx-npm-lib";

import TableCarouselHeaderRowCol from "../../../../../../ui/table-carousel-v2/header/table-carousel-header-row-col.component";
import { ListHeaderExpandButton } from "../../../../../../ui/dragAndDropList/header";
import EvaluationScorecardAssignmentsAvatar from "../../evaluation-scorecard-assignments-avatar.component";
import { getStoredJsonItem, setStoredJsonItem } from "../../../../../../ui/dragAndDropList/lib";
import ScorecardRequirementItem from "./scorecard-requirement-item/scorecard-requirement-item.component";
import { Requirement, Product } from "../../../../../../app.types";
import styles from "./scorecard-requirements.styles.module.scss";

type ScorecardRequirementsProps = {
  initiativeId: UUID;
  requirements: Requirement[];
  onAvatarClick: () => void;
  isOwner: boolean;
  scoringMode: ScoringMode;
  displayedProducts: Product[];
};

const ScorecardRequirementsComponent = ({
  initiativeId,
  requirements,
  onAvatarClick,
  isOwner,
  scoringMode,
  displayedProducts = [],
}: ScorecardRequirementsProps) => {
  const { t } = useTranslation();

  const [requirementsOpenIdxList, setRequirementsListsOpenArray] = useState([-1]);

  const sessionStorageId = `initiatives-evaluation-${initiativeId}`;
  const displayedProductCount = displayedProducts.length;

  const handleClickExpandButton = (clickIndex: number) => {
    if (requirementsOpenIdxList.includes(clickIndex)) {
      const openCopy = requirementsOpenIdxList.filter((index) => {
        return index !== clickIndex;
      });
      setRequirementsListsOpenArray(openCopy);
      const sessionData = getStoredJsonItem(sessionStorageId);
      setStoredJsonItem(sessionStorageId, {
        ...sessionData,
        requirementsOpenIdxList: openCopy,
      });
    } else {
      const openCopy = [...requirementsOpenIdxList];
      openCopy.push(clickIndex);
      setRequirementsListsOpenArray(openCopy);
      const sessionData = getStoredJsonItem(sessionStorageId);
      setStoredJsonItem(sessionStorageId, {
        ...sessionData,
        requirementsOpenIdxList: openCopy,
      });
    }
  };

  return (
    <>
      {requirements.map((requirement, index, arr) => {
        const { id, name, weight, items } = requirement;

        return (
          <TableCarouselHeaderRowCol
            key={id.toString() || index.toString()}
            rootClass={classNames(
              styles.reqRootHeadRow,
              styles.reqScoringAssignment,
              arr.length - 1 === index && "gx-last-req-root"
            )}
          >
            <div
              className={classNames(
                "gx-get-element-height",
                styles.reqRootCategory,
                displayedProductCount === 0 && styles.rightBorder,
                (index === 0 || index === requirements.length - 1) && styles.noRightBorder,
                requirementsOpenIdxList.includes(index) && styles.reqRootCategoryOpen
              )}
            >
              <ListHeaderExpandButton
                expand={requirementsOpenIdxList.includes(index)}
                setExpand={() => handleClickExpandButton(index)}
              />
              <div className={styles.reqRootCategoryNameWeightWrapper}>
                <TypographyComponent boldness={"medium"} rootClassName={styles.reqRootCategoryName} styling={"p2"}>
                  {name || <span>{t("Untitled category")}</span>}
                </TypographyComponent>
                <TypographyComponent boldness={"semi"} rootClassName={styles.reqRootCategoryWeight} styling={"p4"}>
                  {weight}%
                </TypographyComponent>
              </div>
              <EvaluationScorecardAssignmentsAvatar
                categoriesV2={requirements.map((req) => ({
                  evaluators: req.evaluators || [],
                  id: req.id,
                }))}
                id={id}
                isOwner={isOwner}
                mode={scoringMode}
                onClick={onAvatarClick}
              />
            </div>
            <ScorecardRequirementItem
              index={index}
              requirementsOpenIdxList={requirementsOpenIdxList}
              displayProductsCount={displayedProductCount}
              reqItems={items}
            />
            {index === 0 && displayedProductCount === 0 && <div className={styles.rightBorderTop} />}
            {index === requirements.length - 1 && displayedProductCount === 0 && (
              <div className={styles.rightBorderBottom} />
            )}
          </TableCarouselHeaderRowCol>
        );
      })}
    </>
  );
};

export default ScorecardRequirementsComponent;

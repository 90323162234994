// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Exf8WjdZOu3dhdxEkAQX{margin-top:24px}@media(max-width: 1440px){.cMC5K0CWR_FMzaxeMc49{width:638px}}@media(max-width: 1240px){.cMC5K0CWR_FMzaxeMc49{width:683px}}@media(max-width: 1024px){.cMC5K0CWR_FMzaxeMc49{width:572px}}.eLXZvzMuNiRX3eFUVJyV{margin-top:32px;max-width:600px}@media(max-width: 1440px){.eLXZvzMuNiRX3eFUVJyV{max-width:620px}}@media(max-width: 1240px){.eLXZvzMuNiRX3eFUVJyV{max-width:683px}}@media(max-width: 1024px){.eLXZvzMuNiRX3eFUVJyV{max-width:572px}}.eLXZvzMuNiRX3eFUVJyV .QPGW6FochXOxlkdFVPkA{color:var(--color-neutrals-carbon)}.eLXZvzMuNiRX3eFUVJyV .dkmBDkzwV_hqMARoNRQA{margin-top:16px}.pQrxgqfIKnCVShGiXpJN{margin-top:32px}.RiaWhjdzW338qmPhMRSp{position:absolute;width:450px;height:200px}`, "",{"version":3,"sources":["webpack://./src/sections/requirements/title/quick-start/importing-exporting/importing-exporting.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,0BACE,sBACE,WAAA,CAAA,CAIJ,0BACE,sBACE,WAAA,CAAA,CAIJ,0BACE,sBACE,WAAA,CAAA,CAIJ,sBACE,eAAA,CACA,eAAA,CAEA,0BAJF,sBAKI,eAAA,CAAA,CAEF,0BAPF,sBAQI,eAAA,CAAA,CAEF,0BAVF,sBAWI,eAAA,CAAA,CAEF,4CACE,kCAAA,CAGF,4CACE,eAAA,CAIJ,sBACE,eAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".importExportImageContainer {\n  margin-top: 24px;\n}\n\n@media (max-width: 1440px) {\n  .imgSize {\n    width: 638px;\n  }\n}\n\n@media (max-width: 1240px) {\n  .imgSize {\n    width: 683px;\n  }\n}\n\n@media (max-width: 1024px) {\n  .imgSize {\n    width: 572px;\n  }\n}\n\n.description {\n  margin-top: 32px;\n  max-width: 600px;\n\n  @media (max-width: 1440px) {\n    max-width: 620px\n  }\n  @media (max-width: 1240px) {\n    max-width: 683px\n  }\n  @media (max-width: 1024px) {\n    max-width: 572px\n  }\n  .heading {\n    color: var(--color-neutrals-carbon)\n  }\n\n  .additionalInfo {\n    margin-top: 16px;\n  }\n}\n\n.helpLink {\n  margin-top: 32px;\n}\n\n.loader {\n  position: absolute;\n  width: 450px;\n  height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"importExportImageContainer": `Exf8WjdZOu3dhdxEkAQX`,
	"imgSize": `cMC5K0CWR_FMzaxeMc49`,
	"description": `eLXZvzMuNiRX3eFUVJyV`,
	"heading": `QPGW6FochXOxlkdFVPkA`,
	"additionalInfo": `dkmBDkzwV_hqMARoNRQA`,
	"helpLink": `pQrxgqfIKnCVShGiXpJN`,
	"loader": `RiaWhjdzW338qmPhMRSp`
};
export default ___CSS_LOADER_EXPORT___;

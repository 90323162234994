import { Box } from "@material-ui/core";
import classNames from "classnames";
import {
  AwardProductDialog,
  Button,
  MiniButton,
  ProductLogo,
  SnackbarBanner,
  TooltipV2,
  TypographyComponent,
} from "gx-npm-ui";
import {
  getAsyncRequest,
  InitProdState,
  InitUserRole,
  postAsyncRequest,
  useCaptureEventsV2,
  useUserInitAccess,
  UUID,
} from "gx-npm-lib";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import RectangleSVG from "../../../../assets/images/selection/rectangle.svg";
import VendorSvg from "../../../../assets/images/selection/vendors.svg";
import styles from "./pre-selection-view.styles.module.scss";
import { FileTypePdfIcon } from "gx-npm-icons";
import { useContext, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Product, SelectionTabResponse } from "../../selection.types";
import { SelectionContext } from "../../selection.context";
import { customEvents } from "../../../../lib";
import { ClientEvent } from "../../../../app.constants";

type PreselectionViewProps = {
  productsInEval: Product[];
};
const PreSelectionView = ({ productsInEval }: PreselectionViewProps) => {
  const { t } = useTranslation();
  const captureEvent = useCaptureEventsV2();
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const [selectedProductDetails, setSelectedProductDetails] = useState<{
    id: string;
    imageLoc: string;
    name: string;
  }>();
  const [showAwardedProductDialog, setShowAwardedProductDialog] = useState(false);
  const [showError, setShowError] = useState(false);
  const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);
  const {
    setAwardedProduct,
    setAwardedProductOverview,
    setBudget,
    setChecklistPhases,
    setCreatedDate,
    setEvalComment,
    setObjectives,
    setProducts,
    setRequirementCategories,
    setRequirementItemCount,
    setTitle,
    setStatusUpdateDate,
    setStatus,
    setTeamMembers,
    setTemplateName,
    setTemplateDesc,
    setShowAnimation,
  } = useContext(SelectionContext);

  const { role } = useUserInitAccess(initiativeId);

  const navigateToVendorsTab = () => {
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/products`);
  };
  const handleVendorSelect = (e: SelectChangeEvent) => {
    const productId = e.target.value;
    const productDetails = productsInEval?.find((product) => product.id === productId);
    setSelectedProductDetails({
      id: productDetails?.id as UUID,
      name: productDetails?.name as string,
      imageLoc: productDetails?.imageLoc as string,
    });
    setShowAwardedProductDialog(true);
  };

  const downloadSamplePDF = async () => {
    const url = "/assets/templates/buysmart_export_sample_summary.pdf";
    captureEvent([
      {
        eventType: ClientEvent.INITIATIVE_SELECTION_EXPORT_SAMPLE_SUMMARY_PDF_CLICKED,
        metaData: {
          initiativeId,
        },
      },
    ]);
    window.open(url);
  };

  const loadSelectionsData = async () => {
    const selectionsUrl = `/api/v2/initiatives/${initiativeId}/selection`;
    const response: SelectionTabResponse = await getAsyncRequest(selectionsUrl);
    if (response.status === 200 && response.data.data) {
      const {
        title,
        awardedProductOverview,
        budget,
        createdDate,
        checklistPhases,
        evalComment,
        statusUpdateDate,
        objectives,
        products,
        requirementCategories,
        requirementItemCount,
        status,
        teamMembers,
        templateName,
        templateDesc,
      } = response.data.data;

      const awardedProductObject = products.find((product) => product.state === InitProdState.AWARDED) || {
        id: "",
        name: "",
        imageLoc: "",
        overallScoreDisplay: "",
        state: InitProdState.LISTED,
        scorecardCategories: [],
      };

      setAwardedProduct(awardedProductObject);
      setAwardedProductOverview(awardedProductOverview);
      setBudget(budget);
      setCreatedDate(createdDate);
      setChecklistPhases(checklistPhases);
      setEvalComment(evalComment);
      setObjectives(objectives);
      setProducts(products);
      setRequirementCategories(requirementCategories);
      setRequirementItemCount(requirementItemCount);
      setStatus(status);
      setStatusUpdateDate(statusUpdateDate);
      setTeamMembers(teamMembers);
      setTemplateName(templateName);
      setTemplateDesc(templateDesc);
      setTitle(title);
      window.dispatchEvent(new CustomEvent(customEvents.initiativeStatusUpdate, { detail: { status } }));
    } else {
      setShowError(true);
      setConfirmButtonLoading(false);
    }
  };

  const handleAwardProductSelection = async () => {
    setConfirmButtonLoading(true);
    const productId = selectedProductDetails?.id || "";
    const url = `/api/v3/initiatives/${initiativeId}/products/${productId}/action/award`;
    const res = await postAsyncRequest(url, {});
    if (res?.data && [200, 201, 204].indexOf(res?.status) > -1) {
      await loadSelectionsData();
      const showAnimation = localStorage.getItem(`showAnimationOnSelection-${initiativeId}`);
      if (showAnimation === null) {
        localStorage.setItem(`showAnimationOnSelection-${initiativeId}`, "true");
        setShowAnimation(true);
      }
      captureEvent([
        {
          eventType: ClientEvent.INITIATIVE_SELECTION_SELECT_VENDOR_CLICKED,
          metaData: {
            initProductId: productId,
            initiativeId,
          },
        },
      ]);
    } else {
      setShowError(true);
      setConfirmButtonLoading(false);
    }
  };

  const handleCancelProductSelection = () => {
    setShowAwardedProductDialog(false);
  };

  return (
    <>
      <div className={styles.root}>
        <Box component="section" className={styles.content}>
          <TypographyComponent styling="h4" rootClassName={styles.heading} boldness={"medium"}>
            {t("Select a vendor to gain access to your personalized Selection Summary")}
          </TypographyComponent>
          <TypographyComponent styling="p3" color="iron" rootClassName={styles.info1}>
            {t(
              "The Selection Summary will provide a complete breakdown of your team’s evaluation activities as well as Gartner insight behind your selection."
            )}
          </TypographyComponent>
          {productsInEval?.length === 0 && (
            <>
              <TypographyComponent styling="p3" color="iron" boldness="medium" rootClassName={styles.info2}>
                {t("It looks like there are no active vendors in your eval.")}
              </TypographyComponent>
              <TooltipV2
                title={t("This action is only available to evaluation owners.")}
                PopperProps={{ modifiers: { offset: { offset: "0, 10" } } }}
                placement={"right"}
                deactivate={role === InitUserRole.OWNER}
              >
                <span>
                  <Button
                    onClick={navigateToVendorsTab}
                    disabled={role !== InitUserRole.OWNER}
                    rootClassName="btn-primary"
                  >
                    {t("GO TO VENDOR LIST")}
                  </Button>
                </span>
              </TooltipV2>
            </>
          )}
          {productsInEval && productsInEval?.length > 0 && (
            <FormControl className={styles.dropdown}>
              <InputLabel shrink={true}>{t("Select vendor")}</InputLabel>
              <TooltipV2
                title={t("This action is only available to evaluation owners.")}
                PopperProps={{ modifiers: { offset: { offset: "0, 10" } } }}
                placement={"right"}
                deactivate={role === InitUserRole.OWNER}
              >
                <Select
                  fullWidth
                  label={t("Select vendor")}
                  aria-labelledby={"Select vendor"}
                  className={styles.dropdown}
                  notched={true}
                  disabled={role !== InitUserRole.OWNER}
                  onChange={(e) => handleVendorSelect(e as SelectChangeEvent<string>)}
                  value={""}
                >
                  {productsInEval.map((product, index) => {
                    return (
                      <MenuItem key={index} value={product.id}>
                        <div className={styles.productItemContainer} data-product-id={product.id}>
                          <span className={styles.logoWrapper}>
                            <ProductLogo
                              rootClassName={styles.productLogo}
                              logo={product.imageLoc}
                              svgStyle={{ width: "30px" }}
                              name={"product-logo"}
                            />
                          </span>
                          <span>
                            <TypographyComponent styling="p3" boldness="medium">
                              {product.name}
                            </TypographyComponent>
                          </span>
                        </div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </TooltipV2>
            </FormControl>
          )}
          <MiniButton isSvgAffected={false} rootClassName={styles.exportBtn} onClick={downloadSamplePDF}>
            <FileTypePdfIcon />
            <TypographyComponent styling="p3" color="defaultCta" boldness="medium">
              {t("Export a sample summary")}
            </TypographyComponent>
          </MiniButton>
        </Box>
        <img
          alt={t("background triangle")}
          className={classNames(styles.imgWrap, styles.imgRectangle)}
          src={RectangleSVG}
        />
        <img alt={t("example report")} className={classNames(styles.imgWrap, styles.imgVendors)} src={VendorSvg} />
      </div>
      <AwardProductDialog
        awardedProductName={""}
        awardedProductImageLoc={""}
        isLoading={confirmButtonLoading}
        isOpen={showAwardedProductDialog}
        onClose={handleCancelProductSelection}
        onConfirm={handleAwardProductSelection}
        selectionImageLoc={selectedProductDetails?.imageLoc as string}
        selectionName={selectedProductDetails?.name as string}
      />
      <SnackbarBanner isOpen={showError} isDefaultErrorMessage={true} setIsOpen={(val: boolean) => setShowError(val)} />
    </>
  );
};

export default PreSelectionView;

import { weightPalette } from "gx-npm-common-styles/src/palettes";

const requirementTextAreaStyles = {
  root: {
    "&.req-text-name-field": {
      display: "inline-flex",
      flexBasis: "50%",
      marginRight: "2px",
      "& .gx-req-text-area": {
        minWidth: "100%",
        "& textarea": {
          width: "100%",
        },
      },
    },
    "&.req-text-desc-field": {
      width: "100%",
      "& .gx-req-text-area textarea": {
        color: "var(--color-neutrals-coal)",
        fontSize: "14px",
        fontVariationSettings: "unset",
        fontWeight: "normal",
        width: "100%",
      },
    },
    "&.gx-read-only": {
      "& .gx-req-text-area textarea": {
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "transparent",
          outline: 0,
        },
      },
    },
  },
  flexChild: {
    display: "inline-flex",
    margin: "auto 0",
  },
};

const textFieldStyles = {
  requirementsTextField: {
    marginLeft: 0,
    "& .gx-req-text-field": {
      "& .gx-text-field-container": {
        "& input": {
          color: "var(--color-neutrals-carbon)",
          fontSize: "18px",
          fontVariationSettings: weightPalette.semiBold.variation,
          fontWeight: weightPalette.semiBold.amount,
          letterSpacing: "0.25px",
          lineHeight: "25.2px",
          margin: "20px 0",
          padding: "12px 10px 11px",
          "&:focus, &:focus:hover": {
            border: "2px solid var(--color-interactions-default-cta)",
            padding: "10px 10px 9px",
            margin: "20px -2px",
          },
          "&:hover": {
            border: "1px solid var(--color-neutrals-stone)",
            padding: "11px 10px 10px",
            margin: "20px -1px",
          },
          "&:placeholder-shown": {
            color: "var(--color-neutrals-iron)",
            fontVariationSettings: weightPalette.regular.amount,
            fontWeight: weightPalette.regular.amount,
          },
          "&::selection": {
            backgroundColor: "var(--color-interactions-light-berry)",
          },
        },
        "& textarea": {
          color: "var(--color-neutrals-carbon)",
          fontSize: "18px",
          fontVariationSettings: weightPalette.medium.variation,
          fontWeight: weightPalette.medium.amount,
          letterSpacing: "0.25px",
          lineHeight: "25.2px",
          padding: "12px 10px 11px",
          borderRadius: "4px",
          marginRight: "12px",
          marginLeft: "4px",
          "&:focus, &:focus:hover": {
            border: "2px solid var(--color-interactions-default-cta)",
            padding: "12px 10px 11px",
            margin: "-2px 2px",
            marginRight: "10px",
          },
          "&:hover": {
            border: "1px solid var(--color-neutrals-stone)",
            padding: "11px 9px 10px",
            // margin: "0px -1px",
          },
          "&:placeholder-shown": {
            color: "var(--color-neutrals-iron)",
            fontVariationSettings: weightPalette.regular.variation,
            fontWeight: weightPalette.regular.amount,
          },
          "&::selection": {
            backgroundColor: "var(--color-interactions-light-berry)",
          },
        },
        "& div:first-of-type": {
          height: "inherit",
          "& input.Mui-disabled, & textfield.Mui-disabled": {
            textFillColor: "inherit",
          },
          "&.Mui-disabled fieldset": {
            border: "none",
          },
        },
      },
    },
    "&.gx-read-only": {
      "& .gx-req-text-field input": {
        "&:hover, &:focus, &:focus:hover": {
          border: 0,
          margin: "20px 0",
          padding: "12px 10px 11px",
        },
      },
      "& .gx-req-text-field textarea": {
        "&:hover, &:focus, &:focus:hover": {
          border: 0,
          margin: "20px 0",
          padding: "12px 10px 11px",
        },
      },
    },
  },
};

export { requirementTextAreaStyles, textFieldStyles };

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lY1wBo3ck78lykWMByqz{margin-top:24px}@media(max-width: 1440px){.NImSvngRrFbrTdSYtky6{width:638px}}@media(max-width: 1240px){.NImSvngRrFbrTdSYtky6{width:683px}}@media(max-width: 1024px){.NImSvngRrFbrTdSYtky6{width:572px}}.JeR1fzul4UmjHpLNBm0f{margin-top:32px;max-width:600px}@media(max-width: 1440px){.JeR1fzul4UmjHpLNBm0f{max-width:620px}}@media(max-width: 1240px){.JeR1fzul4UmjHpLNBm0f{max-width:683px}}@media(max-width: 1024px){.JeR1fzul4UmjHpLNBm0f{max-width:572px}}.JeR1fzul4UmjHpLNBm0f .IsU_oFtZWlt_GDSID2FQ{color:var(--color-neutrals-carbon)}.JeR1fzul4UmjHpLNBm0f .TUxdcuVVGXfRNvaCLCL9{margin-top:16px}.wlxyCiwbPRiExte61V1m{margin-top:32px}.xxtXQSTUiHFmHyp9ouo7{position:absolute;width:450px;height:200px}`, "",{"version":3,"sources":["webpack://./src/sections/requirements/title/quick-start/scoring-weights/scoring-weights.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,0BACE,sBACE,WAAA,CAAA,CAIJ,0BACE,sBACE,WAAA,CAAA,CAKJ,0BACE,sBACE,WAAA,CAAA,CAIJ,sBACE,eAAA,CACA,eAAA,CACA,0BAHF,sBAII,eAAA,CAAA,CAEF,0BANF,sBAOI,eAAA,CAAA,CAEF,0BATF,sBAUI,eAAA,CAAA,CAGF,4CACE,kCAAA,CAGF,4CACE,eAAA,CAIJ,sBACE,eAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".scoringWeightsImageContainer {\n  margin-top: 24px;\n}\n\n@media (max-width: 1440px) {\n  .imgSize {\n    width: 638px;\n  }\n}\n\n@media (max-width: 1240px) {\n  .imgSize {\n    width: 683px;\n  }\n\n}\n\n@media (max-width: 1024px) {\n  .imgSize {\n    width: 572px;\n  }\n}\n\n.description {\n  margin-top: 32px;\n  max-width: 600px;\n  @media (max-width: 1440px) {\n    max-width: 620px\n  }\n  @media (max-width: 1240px) {\n    max-width: 683px\n  }\n  @media (max-width: 1024px) {\n    max-width: 572px\n  }\n\n  .heading {\n    color: var(--color-neutrals-carbon)\n  }\n\n  .additionalInfo {\n    margin-top: 16px;\n  }\n}\n\n.helpLink {\n  margin-top: 32px;\n}\n\n.loader {\n  position: absolute;\n  width: 450px;\n  height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scoringWeightsImageContainer": `lY1wBo3ck78lykWMByqz`,
	"imgSize": `NImSvngRrFbrTdSYtky6`,
	"description": `JeR1fzul4UmjHpLNBm0f`,
	"heading": `IsU_oFtZWlt_GDSID2FQ`,
	"additionalInfo": `TUxdcuVVGXfRNvaCLCL9`,
	"helpLink": `wlxyCiwbPRiExte61V1m`,
	"loader": `xxtXQSTUiHFmHyp9ouo7`
};
export default ___CSS_LOADER_EXPORT___;

import { getAsyncRequest, InitProdState, InitStatus, useCaptureEventsV2, UUID } from "gx-npm-lib";
import { SnackbarBanner } from "gx-npm-ui";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientEvent, CustomAppEvent } from "../../app.constants";
import { TabSectionLoader } from "../../ui/loader";
import AnimationSelectionView from "./components/animation-selection-view/animation-selection-view.component";
import PreSelectionView from "./components/pre-selection-view/pre-selection-view.component";
import SelectionReport from "./components/selection-report/selection-report.component";
import { SelectionContext } from "./selection.context";
import { SelectionTabResponse } from "./selection.types";

type SelectionTabContentV2Props = { initiativeId: UUID; onNavigation?: (name: string) => void };
const SelectionTabContentV2Component = ({ initiativeId = "" }: SelectionTabContentV2Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    products: initProducts,
    awardedProduct,
    selectionEditCount,
    status: initStatus,
    isSnackbarOpen,
    showAnimation,
    setAwardedProduct,
    setAwardedProductOverview,
    setBudget,
    setChecklistPhases,
    setCreatedDate,
    setEvalComment,
    setIsSnackbarOpen,
    setInitiativeId,
    setObjectives,
    setProducts,
    setRequirementCategories,
    setRequirementItemCount,
    setTitle,
    setStatusUpdateDate,
    setStatus,
    setTeamMembers,
    setTemplateName,
    setTemplateDesc,
    setShowAnimation,
  } = useContext(SelectionContext);
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    setIsLoading(true);
    (async () => {
      const url = `/api/v2/initiatives/${initiativeId}/selection`;
      const response: SelectionTabResponse = await getAsyncRequest(url);
      if (response.status === 200 && response.data?.data) {
        const {
          awardedProductOverview,
          budget,
          createdDate,
          checklistPhases,
          evalComment,
          title,
          objectives,
          products,
          requirementCategories,
          requirementItemCount,
          statusUpdateDate,
          status,
          teamMembers,
          templateName,
          templateDesc,
        } = response.data.data;

        const awardedProductObject = products.find((product) => product.state === InitProdState.AWARDED) || {
          id: "",
          name: "",
          imageLoc: "",
          overallScoreDisplay: "",
          state: InitProdState.LISTED,
          scorecardCategories: [],
        };

        const isAnimationShown = JSON.parse(
          localStorage.getItem(`showAnimationOnSelection-${initiativeId}`) || "false"
        );

        setShowAnimation(isAnimationShown);
        setAwardedProduct(awardedProductObject);
        setAwardedProductOverview(awardedProductOverview);
        setBudget(budget);
        setCreatedDate(createdDate);
        setChecklistPhases(checklistPhases);
        setEvalComment(evalComment);
        setInitiativeId(initiativeId);
        setObjectives(objectives);
        setProducts(products);
        setRequirementCategories(requirementCategories);
        setRequirementItemCount(requirementItemCount);
        setStatus(status);
        setStatusUpdateDate(statusUpdateDate);
        setTeamMembers(teamMembers);
        setTemplateName(templateName);
        setTemplateDesc(templateDesc);
        setTitle(title);
      } else {
        setIsSnackbarOpen(true);
      }
      setIsLoading(false);
    })();
  }, [
    setAwardedProduct,
    setAwardedProductOverview,
    setBudget,
    setChecklistPhases,
    setCreatedDate,
    initiativeId,
    setIsSnackbarOpen,
    setEvalComment,
    setInitiativeId,
    setObjectives,
    setProducts,
    setRequirementCategories,
    setRequirementItemCount,
    setStatus,
    setStatusUpdateDate,
    setTeamMembers,
    setTemplateName,
    setTemplateDesc,
    setTitle,
    setShowAnimation,
  ]);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent(CustomAppEvent.SELECTION_EDIT_COUNT, { detail: { count: selectionEditCount } })
    );
  }, [selectionEditCount]);

  useEffect(() => {
    captureEvents([
      {
        eventType: ClientEvent.INITIATIVE_SELECTION_PAGE_VIEWED,
        metaData: { initiativeId },
      },
    ]);
  }, [captureEvents, initiativeId]);

  const handleOnAnimationComplete = () => {
    const storageId = `showAnimationOnSelection-${initiativeId}`;
    localStorage.setItem(storageId, "false");
    setShowAnimation(false);
  };

  const handleCloseSnackbar = () => {
    setIsSnackbarOpen(false);
  };

  const renderSelection = () => {
    if (initStatus === InitStatus.SELECTED && awardedProduct.id) {
      return <SelectionReport />;
    }

    return (
      <PreSelectionView
        productsInEval={initProducts.filter((product) => product.state === InitProdState.IN_EVALUATION)}
      />
    );
  };

  return (
    <>
      {isLoading && <TabSectionLoader />}
      {!isLoading && <AnimationSelectionView onComplete={handleOnAnimationComplete} />}
      {!isLoading && !showAnimation && renderSelection()}
      <SnackbarBanner
        isOpen={isSnackbarOpen}
        message={t("Oops... something went wrong. Please try again.")}
        setIsOpen={handleCloseSnackbar}
      />
    </>
  );
};

export default SelectionTabContentV2Component;

import { weightPalette } from "gx-npm-common-styles/src/palettes";

const requirementPopoverMenuStyles = {
  flexChild: {
    display: "inline-flex",
    marginRight: 0,
    order: 2,
  },
  menuRoot: {
    flexBasis: "140px",
    "& .gx-req-popover-menu": {
      margin: "auto 0 auto 8px",
      padding: "0 2px",
      verticalAlign: "top",
      width: "132px",
      "& .gx-popover-menu-list": {
        top: "32px",
      },
      "&.gx-req-popover-menu-icon-btn .gx-popover-menu-list": {
        top: "45px",
        left: "-74px",
      },
      "&.gx-req-popover-menu-icon-btn .gx-popover-menu-list.gx-menu-list-auto-width": {
        top: "52px",
        left: "-2px",
      },
      "& .gx-popover-menu-selected": {
        height: "unset",
        "& .p3": {
          color: "var(--color-neutrals-carbon)",
          fontSize: "14px",
          fontVariationSettings: weightPalette.medium.variation,
          fontWeight: weightPalette.medium.amount,
          letterSpacing: "0.25px",
          lineHeight: "21px",
        },
      },
    },
  },
};

export { requirementPopoverMenuStyles };

import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Collapse } from "@material-ui/core";
import { TypographyComponent } from "gx-npm-ui";
import { RequirementItem } from "../../../../../../../app.types";
import styles from "./scorecard-requirement-item.styles.module.scss";

type ScorecardRequirementItemProps = {
  index: number;
  reqItems: RequirementItem[];
  requirementsOpenIdxList: number[];
  displayProductsCount: number;
};

const ScorecardRequirementItem = ({
  index,
  reqItems,
  requirementsOpenIdxList,
  displayProductsCount,
}: ScorecardRequirementItemProps) => {
  const { t } = useTranslation();

  return (
    <Collapse className={styles.reqItemNameContainer} in={requirementsOpenIdxList.includes(index)}>
      <ul className={classNames(styles.reqItemList, displayProductsCount === 0 && styles.rightBorder)}>
        {reqItems.map(({ name, id }) => (
          <li key={id} className={classNames(styles.reqItem, "gx-get-element-height")}>
            <TypographyComponent color={"coal"}>
              {name || (
                <TypographyComponent rootClassName={styles.italic} color={"coal"}>
                  {t("Untitled requirement")}
                </TypographyComponent>
              )}
            </TypographyComponent>
          </li>
        ))}
      </ul>
    </Collapse>
  );
};

export default ScorecardRequirementItem;

import React, { FC } from "react";

type propTypes = {
  children: React.ReactNode;
  rootClass?: string;
};

const TableCarouselBodyRow: FC<propTypes> = ({ children, rootClass }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    return React.isValidElement(child) ? React.cloneElement(child) : child;
  });
  return <tr className={rootClass}>{childrenWithProps}</tr>;
};

export default TableCarouselBodyRow;

import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./animation-selection-view.styles";
import classNames from "classnames";
import selectionAnimation from "../../../../assets/lottie/selectionAnimation.json";
import React, { useContext } from "react";
import { TypographyComponent } from "gx-npm-ui";
import { SelectionContext } from "../../selection.context";
import Lottie from "react-lottie-player";

const useStyles = makeStyles(() => styles);

type AnimationSelectionViewProps = { onComplete: () => void };
const AnimationSelectionView = ({ onComplete }: AnimationSelectionViewProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { showAnimation } = useContext(SelectionContext);

  return (
    <div>
      {showAnimation && (
        <TypographyComponent rootClassName={classNames(classes.animationCopy)}>
          {t("We're gathering your selection summary ...")}
        </TypographyComponent>
      )}
      <Lottie
        play={showAnimation}
        loop={false}
        onComplete={onComplete}
        style={{ height: "437px", width: "349px", margin: "0 auto", display: showAnimation ? "block" : "none" }}
        animationData={selectionAnimation}
      />
    </div>
  );
};

export default AnimationSelectionView;

import { useCaptureEventsV2, UUID } from "gx-npm-lib";
import { useParams, useSearchParams } from "react-router-dom";
import TabPanelComponent from "../../ui/tab-panel/tab-panel.component";
import { OverviewContextProvider } from "./overview.context";
import OverviewTabContentV2 from "./overview-tab-content-v2.component";
import { useEffect } from "react";
import { ClientEvent } from "../../app.constants";

const OverviewTabV2 = () => {
  const { initiativeId = "" } = useParams<{
    initiativeId: UUID;
  }>();
  const [searchParams] = useSearchParams();
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    const gxtmSource = searchParams.get("gxtm_source") || "";
    const gxtmType = searchParams.get("gxtm_type") || "";
    captureEvents([
      {
        eventType: ClientEvent.INITIATIVE_OVERVIEW_PAGE_VIEWED,
        metaData: { initiativeId, gxtmType, gxtmSource },
      },
    ]);
  }, [captureEvents, initiativeId, searchParams]);
  return (
    <OverviewContextProvider>
      <TabPanelComponent>
        <OverviewTabContentV2 initiativeId={initiativeId} />
      </TabPanelComponent>
    </OverviewContextProvider>
  );
};

export default OverviewTabV2;

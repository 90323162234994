import { useCaptureEventsV2, UUID } from "gx-npm-lib";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ClientEvent } from "../../app.constants";
import TabPanelComponent from "../../ui/tab-panel/tab-panel.component";
import { ChecklistContextProvider } from "./checklist.context";
import ChecklistTabContentV3Component from "./checklist-tab-content-v3.component";

const ChecklistTabV3Component = () => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const [searchParams] = useSearchParams();
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    const gxtmSource = searchParams.get("gxtm_source") || "";
    const gxtmType = searchParams.get("gxtm_type") || "";
    captureEvents([
      {
        eventType: ClientEvent.INITIATIVE_CHECKLIST_PAGE_VIEWED,
        metaData: { initiativeId, gxtmType, gxtmSource },
      },
    ]);
  }, [captureEvents, initiativeId, searchParams]);

  return (
    <ChecklistContextProvider>
      <TabPanelComponent>
        <ChecklistTabContentV3Component />
      </TabPanelComponent>
    </ChecklistContextProvider>
  );
};

export default ChecklistTabV3Component;

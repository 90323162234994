import React from "react";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "gx-npm-ui";
import styles from "./adding-scores.styles.module.scss";
import AddingScoresImg from "../../../../../../../assets/images/quick-start/scorecard/adding-scores.svg";
import classNames from "classnames";

const AddingScoresComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <TypographyComponent styling={"h5"} boldness={"medium"}>
        {t("Adding scores")}
      </TypographyComponent>
      <img
        src={AddingScoresImg}
        alt={t("Adding scores")}
        className={classNames(styles.addingScoresImg, styles.imgSize)}
      />
      <div className={styles.description}>
        <TypographyComponent rootClassName={styles.heading} styling={"p2"} boldness={"medium"}>
          {t(
            "Every team member who has been assigned to score will have access to their own version of the scoresheet - available in the ‘My Scoresheet’ tab of the vendor workspace."
          )}
        </TypographyComponent>
        <TypographyComponent rootClassName={styles.additionalInfo} styling={"p2"} boldness={"regular"}>
          {t(
            "Comments and key takeaways on the scoresheet are private, but can be seen by anyone who exports the scorecard."
          )}
        </TypographyComponent>
      </div>
    </>
  );
};

export default AddingScoresComponent;
